@import '../../../../scss/theme-bootstrap';

.product--full {
  @include pie-clearfix;
  // There is no .product inside of product--full, this is just used for
  // prefixing element-level BEM classes with the block identifier.
  .product {
    &-gallery {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 40%;
        float: $ldirection;
        .quickshop & {
          width: 50%;
        }
      }
      img.active {
        border: none;
      }
    }
    &__header {
      @include breakpoint($medium-up) {
        margin-bottom: 15px;
      }
    }
    &__details {
      padding: 0;
      width: 100%;
      @include breakpoint($medium-up) {
        padding: 0;
        width: 60%;
        float: $rdirection;
        .quickshop & {
          width: 50%;
        }
      }
      .content {
        padding: 22px;
      }
      &_wrap {
        @include breakpoint($small-down) {
          padding: 0 20px;
        }
      }
    }
    &__title {
      @include h4;
      letter-spacing: 0;
      &-link {
        color: $color-black;
        text-decoration: none;
      }
    }
    &__subline,
    &__subtitle {
      @include h6;
      &-link {
        color: $color-black;
        text-decoration: none;
      }
    }
    &__rating {
      @include h7;
      text-transform: inherit;
      font-family: $main-font;
      padding: 0 0 18px;
      @include breakpoint($medium-up) {
        display: inline-block;
      }
    }
    &__qty,
    &__weight-container {
      margin-bottom: 10px;
      @include breakpoint($medium-up) {
        margin: 0;
      }
      .selectbox.product__options {
        border: 1px solid $color-black;
        line-height: 30px;
        @include breakpoint($medium-up) {
          max-width: 151px;
        }
        &.selectbox.product__weight--list {
          margin-top: 7px;
          @include breakpoint($medium-up) {
            max-width: none;
            min-width: 190px;
          }
        }
        .selectBox-arrow {
          background-image: url('/media/images/global/arrow_down_black.png');
          background-position: center center;
          background-repeat: no-repeat;
          border-#{$ldirection}: 1px solid $color-black;
          width: 31px;
          &::before {
            content: none;
          }
        }
        &.selectBox-menuShowing .selectBox-arrow {
          border-#{$ldirection}: medium none;
          border-#{$rdirection}: 1px solid $color-black;
        }
      }
    }
    &__delivery {
      position: relative;
      & > span {
        display: none;
      }
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 20px;
        & > .product__options {
          @include swap_direction(margin, 10px 0 15px 150px);
        }
        & > span {
          display: inline-block;
        }
      }
      &::after {
        content: '?';
        position: absolute;
        #{$rdirection}: 0;
        top: 5px;
        @include breakpoint($medium-up) {
          content: none;
        }
      }
    }
    &__options {
      @include breakpoint($small-down) {
        display: block;
        margin: 0 auto;
        width: 90%;
      }
    }
    &__info {
      @include pie-clearfix;
    }
    &__sku-categories {
      @include breakpoint($medium-up) {
        min-height: 240px;
      }
      .resp-tab-active {
        border-bottom: solid 1px $color-white;
        background-color: $color-white;
      }
      .resp-tab-content {
        border: 0;
        padding: 0;
      }
    }
    &__sku-categories-nav {
      display: table;
      margin-top: 0;
      margin-bottom: 0;
      border-#{$ldirection}: solid 1px $color-black;
    }
    &__sku-categories-nav-item {
      @include h8;
      font-size: 13px;
      display: table-cell;
      padding: 10px;
      background-color: $color-beige;
      border: 1px solid $color-black;
      border-width: 0 1px 1px 0;
      cursor: pointer;
      float: none;
      text-align: center;
      &.resp-tab-active {
        border-bottom: 1px solid $color-white;
        background-color: $color-white;
      }
    }
    &__sku-categories-nav-bottom {
      position: relative;
      z-index: -1;
      margin: -1px 0 20px 0;
      border-bottom: 1px solid $color-black;
    }
    &__weight,
    &__weight-container {
      @include main-font-medium;
      line-height: get-line-height(9px, 14px);
      text-align: center;
      color: $color-gray-dark;
      font-size: 9px;
      letter-spacing: 0;
      @include breakpoint($medium-up) {
        float: $ldirection;
        text-align: inherit;
        font-weight: 700;
        font-size: 13px;
        line-height: get-line-height(13px, 21px);
      }
    }
    &__weight-container {
      visibility: visible;
    }
    &__unit-price {
      @include main-font-bold;
      line-height: get-line-height(13px, 21px);
      float: $ldirection;
      margin-#{$ldirection}: 10px;
      color: $color-gray-dark;
      font-size: 13px;
      letter-spacing: 0;
      @include breakpoint($small-down) {
        float: none;
        text-align: center;
        font-weight: 500;
      }
    }
    &__price {
      @include h5;
      clear: both;
      padding-top: 7px;
      span.old_price {
        @include breakpoint($medium-up) {
          display: inline-block;
          text-decoration: line-through;
          line-height: get-line-height(22px, 19px);
          font-size: 22px;
        }
        @include breakpoint($small-down) {
          text-decoration: line-through;
        }
      }
      span.discount_price {
        @include breakpoint($medium-up) {
          color: $color-red;
          line-height: get-line-height(22px, 19px);
          font-size: 22px;
        }
        @include breakpoint($small-down) {
          color: $color-red;
        }
      }
      @include breakpoint($small-down) {
        margin-bottom: 21px;
        text-align: center;
        font-size: 22px;
        padding-top: 10px;
      }
    }
    &__button--add-to-bag {
      letter-spacing: 0.006em;
      @include breakpoint($small-down) {
        @include swap_direction(padding, 9px 27px 7px 13px);
        width: 100%;
        font-size: 12px;
        letter-spacing: 0;
        &.button--disabled {
          padding-#{$rdirection}: 13px;
        }
      }
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 8px 28px 9px 11px);
        margin-#{$rdirection}: 15px;
        font-size: 14px;
        &.button--disabled {
          padding-#{$rdirection}: 11px;
        }
      }
    }
    &__inventory-status {
      .limited-remaining {
        color: $color-red;
        padding-top: 5px;
      }
    }
    &__favorite {
      padding-#{$rdirection}: 10px;
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      span {
        display: inline-block;
        text-align: center;
        min-width: 22px;
        vertical-align: middle;
      }
      .icon {
        color: $color-black;
        &:hover,
        &.icon-heart {
          color: $color-pink;
        }
      }
    }
    &__actions {
      text-align: center;
      padding: 30px 0 21px;
      @include breakpoint($small-down) {
        padding: 4px 0 17px 0;
        display: block;
        margin: 0 auto;
        width: 90%;
      }
      @include breakpoint($medium-up) {
        text-align: $ldirection;
      }
      .product_full_details {
        float: $ldirection;
        margin-top: 9px;
      }
    }
    &__full-details-link-wrapper {
      float: $ldirection;
      margin-top: -2px;
    }
    &__social {
      @include breakpoint($small-down) {
        padding: 0 0 18px 0;
        text-align: $ldirection;
        width: 90%;
        margin: 0 auto;
        font-size: 12px;
        font-weight: 500;
      }
      @include breakpoint($medium-up) {
        display: inline-block;
        position: absolute;
        #{$rdirection}: 0;
        top: 139px;
      }
      a {
        color: $color-black;
        text-decoration: none;
        display: inline-block;
        margin-#{$rdirection}: 10px;
        font-size: 18px;
      }
      span {
        &:first-child {
          font-weight: 500;
          letter-spacing: 0;
          padding-#{$rdirection}: 5px;
        }
      }
      .icon-pinterest {
        background: url('/media/images/global/icon-black-pinterest.png') no-repeat 0 0 $color-white;
        text-decoration: none;
        width: 24px;
        height: 23px;
        &::before {
          content: '';
        }
      }
      .icon-facebook {
        background: url('/media/images/global/icon-black-facebook.png') no-repeat 0 0 $color-white;
        text-decoration: none;
        width: 25px;
        height: 25px;
        &::before {
          content: '';
        }
      }
      .icon-twitter {
        background: url('/media/images/global/icon-black-twitter.png') no-repeat 0 0 $color-white;
        text-decoration: none;
        width: 26px;
        height: 25px;
        &::before {
          content: '';
        }
      }
    }
    &__promo {
      background: $color-light-brown;
      font-size: 12px;
      letter-spacing: 0.24em;
      text-transform: uppercase;
      text-align: $rdirection;
      bottom: 0;
      #{$rdirection}: 0;
      padding: 10px;
      line-height: 1.5;
      @include breakpoint(portrait) {
        width: 100%;
        #{$ldirection}: 0;
        border-top: 1px solid $color-gray;
        margin-#{$ldirection}: 0;
      }
      strong {
        font-weight: normal;
        font-family: $main-font;
      }
    }
    &__rating--reviews-link {
      font-size: 12px;
      letter-spacing: 1px;
      text-decoration: none;
      color: $color-black;
    }
    &__rating {
      @include h8;
      text-transform: inherit;
      padding: 0;
      @include breakpoint($medium-up) {
        font-size: 13px;
        margin-top: 5px;
      }
    }
    &__header {
      @include breakpoint($small-down) {
        // setting up this as a position context, for mobile only items,
        // like the favorites button near the product name
        position: relative;
        .product__favorite {
          position: absolute;
          top: 0;
          #{$rdirection}: 10px;
          font-size: 18px;
        }
        .product__title {
          font-size: 14px;
          text-align: center;
          display: block;
          margin: 20px 20px 0;
          font-weight: 500;
          line-height: get-line-height(14px, 18px);
        }
        .product__price {
          float: none;
          text-align: center;
        }
        .product__rating {
          width: 55%;
          margin: 3px auto 5px auto;
          padding-top: 0;
          text-align: center;
          border-top: 1px solid $color-gray;
          a div.mobile-block {
            display: inline;
          }
        }
      }
    }
    &__details {
      @include breakpoint($small-down) {
        .product__header {
          display: none;
        }
      }
    }
    &__tip {
      font-style: italic;
      text-align: center;
      padding: 50px 20px 85px 20px;
      font-size: 14px;
      border-top: 1px solid $color-black;
      .product__tip-title {
        @include h8;
        font-style: normal;
        margin-bottom: 25px;
      }
    }
    &__no-tip-spacer {
      margin: 0;
      padding: 0;
    }
  } /* end .product */
}

.spp-tooltip {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid $base-border-color;
  padding: 20px;
  .spp-testimonial__tooltip-quote {
    font-size: 32px;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 14px;
  }
  .spp-testimonial__tooltip-name,
  .spp-testimonial__tooltip-location {
    font-size: 11px;
    letter-spacing: 4px;
    line-height: 1.5em;
    text-transform: uppercase;
  }
}
