/**
 * @file global/_functions.scss
 *
 * \brief Functions
 *
 * Useful functions to help us calculate various values
 * (Credit to Zurb Foundation, http://foundation.zurb.com/docs)
 */

// 2019 Redesign
// New line height function to generate unitless numbers vs the px values in the styleguide
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}
